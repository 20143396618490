<!--Inicio-->
<app-parallax [ponerLogo]="true" [nameId]="'paral1'" [imgUrl]="'url(assets/images/inicio.gif)'" [minHeight]="'100vh'">
</app-parallax>


<a id="scrollBtn" href="#top" class="btn btn-floating btn-scrollUp waves-effect wow  fadeInUp" hidden>
    <i class="fas fa-arrow-up text-white"></i>
</a>

<!--Carousel 1-->
<app-carousel [carouselId]="'car1'" [carHeight]="'400px'" [slides]="[
            {slideNumber:0,image: 'assets/images/1.jpg', isActive: 'active',titulo: 'Tu Socio tecnológico', descripcion: 'Brindándote toda la asesoría para llegar al mejor resultado, según tus necesidades.'},
            {slideNumber:1,image: 'assets/images/2.jpg', isActive: '',titulo: 'Performance y diseño', descripcion: 'Frontend y Backend con las últimas tecnologías.'},
            {slideNumber:2,image: 'assets/images/3.jpg', isActive: '',titulo: 'Diseño gráfico y 3D', descripcion: 'Diseños creativos ajustados a tus necesidades.'}, 
            {slideNumber:3,image: 'assets/images/4.jpg', isActive: '',titulo: 'Soluciones CI/CD ', descripcion: 'Generamos soluciones de integración y desarrollo continuos.'}
        ]">

</app-carousel>


<!--Descripcion general-->
<div class="descripcion-container">
    <h1 class="h1-formato">Te ofrecemos asesoramiento y soluciones para tu empresa a través de: <br/>Proyectos Web, Desarrollo de software, diseño gráfico y 3D</h1>
    <h2 class="h2-formato color-gris">5 años de experiencia potenciando empresas, PYMES y proyectos familiares, acercando la tecnología de manera fácil y sencilla, para abrirte la puerta al futuro.</h2>
</div>

<!-- Proposito -->
<div class="proposito-container col-sm-8 offset-sm-2 ">
    <section>
        <!-- Titulo -->
        <h1 class="h1-formato mt-4" data-aos="fade-down" data-aos-duration="1000">Mi propósito</h1>
        <hr class="mx-3" />

        <!-- Description -->
        <h2 class="h2-formato color-gris" data-aos="fade-down" data-aos-duration="1000 ">Es hacer tu vida más fácil, resolviendo tus problemas y necesidades, acercando la tecnología a tus manos de la mejor manera posible.</h2>

        <!-- grilla de opciones -->
        <div class="row ">

            <!-- Analitica -->
            <div class="col-md-4 mb-4 " data-aos="fade-right" data-aos-duration="1000" data-aos-delay="100">
                <i class="fas fa-4x fa-chart-area icon-color"></i>
                <h4 class="h4-formato">Analítica</h4>
                <p class="color-gris">Trabajo de forma analítica para lograr los mejores resultados y proporcionarte una solución de visión general que mejore el rumbo de tu empresa.</p>
            </div>

            <!-- Diseño -->
            <div class="col-md-4 mb-4 " data-aos="fade-right" data-aos-duration="1000" data-aos-delay="300">
                <i class="fas fa-4x fa-pencil-alt icon-color"></i>
                <h4 class="h4-formato">Diseño</h4>
                <p class="color-gris">Utilizo las mejores herramientas para poder brindarte diseños creativos que acompañen tus emprendimientos.</p>
            </div>

            <!-- Desarrollo -->
            <div class="col-md-4 mb-4 " data-aos="fade-right" data-aos-duration="1000" data-aos-delay="500">
                <i class="fas fa-4x fa-laptop icon-color"></i>
                <h4 class="h4-formato">Desarrollo</h4>
                <p class="color-gris">Desarrollo de aplicaciones a medida, enfocándonos en tus requerimientos específicos y haciendote la experiencia intuitiva y eficiente.</p>
            </div>

        </div>
    </section>
</div>

<!--Momento es ahora-->
<app-parallax class="text-center " [nameId]=" 'paral2' " [imgUrl]=" 'url(assets/images/momentoAhora.jpg)' " [minHeight]=" '350px' " [titulo]=" 'El momento es ahora' " [descripcion]="
    'Ingresa ya a la era digital e impulsa tu negocio a un nuevo nivel.' "></app-parallax>

<!--muestra de portagolio-->
<div class="d-flex justify-content-center">

    <section class="col-sm-9  pt-3">
        <!--Cabezal-->
        <div class="p-3 text-center">
            <div data-aos="zoom-in" data-aos-duration="2000">
                <h1 class="h1-formato">Algunos de mis proyectos</h1>
                <h2 class="h2-formato">Ejemplos de proyectos web y de diseño realizados </h2>
            </div>

            <!--separador-->
            <div data-aos="fade-down" data-aos-duration="1000" class="separador col-sm-10 offset-sm-1">
                <div class="col-5 p-0 mr-2">
                    <hr />
                </div>
                <div>
                    <a href="#top" data-toggle="tooltip" title="Inicio">
                        <img src="favicon.ico" style="height: 30px;" />
                    </a>
                </div>
                <div class="col-5 p-0 ml-2">
                    <hr />
                </div>
            </div>
        </div>

        <!--linea de cards-->
        <div class="row justify-content-center p-4">

            <app-card-custom class="col-xl-4 col-md-6 col-12 pb-4" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="100" [imageSrc]="'assets/images/madriguera.jpg'" [demoSrc]="'https://madrigueralibros.sursoft.org/'" [targetDemo]="'blank'" [srcSaberMas]="'portafolio/3/madriguera'"
                [title]="'La Madriguera Libros'" [description]="'Aplicación Ecommerce para la venta de libros y manejos de stock y finanzas de la empresa'">
            </app-card-custom>

            <app-card-custom class="col-xl-4 col-md-6 col-12 pb-4" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="200" [imageSrc]="'assets/images/odonto.jpg'" [demoSrc]="'http://dentalnet.sursoft.org/'" [targetDemo]="'blank'" [srcSaberMas]="'portafolio/3/odonto'"
                [title]="'Odonto Integral'" [description]="'Aplicación para la gestión de clínicas odontólogicas, en la que se implementan historias clínicas y gestión de personal, agenda de pacientes, aranceles de laboratorios, entre otras funcionalidades.'">
            </app-card-custom>

            <app-card-custom class="col-xl-4 col-md-6 col-12 pb-4" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="300" [imageSrc]="'assets/images/lunch.png'" [demoSrc]="'http://lunchboxdemo.sursoft.org/'" [targetDemo]="'blank'" [srcSaberMas]="'portafolio/3/lunch'"
                [title]="'Lunch Box Catering'" [description]="'Aplicación semi Ecommerce en la que se implementa control de stock automático según los trabajos de los empleados, control de actividad de los mismos, generaccón de presupuestos para fiestas, entre otras funcionalidades.'">
            </app-card-custom>

            <app-card-custom class="col-xl-4 col-md-6 col-12 pb-4" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="400" [imageSrc]="'assets/images/3dmodels.jpg'" [demoSrc]="'https://sketchfab.com/sebaualde/models'" [targetDemo]="'blank'" [srcSaberMas]=""
                [btnText]="'Ver modelos'" [title]="'Diseños 3D'" [description]="'Visita mi página en sketchfab para navegar por los diferentes modelos que he creado.'">
            </app-card-custom>

            <app-card-custom class="col-xl-4 col-md-6 col-12 pb-4" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="500" [imageSrc]="'assets/images/disenio.jpg'" [demoSrc]="'portafolio/1'" [srcSaberMas]="" [btnText]="'Ver diseños'" [title]="'Diseños gráficos'"
                [description]="'Una muestra de algunos de los muchos diseños realizados para nuestros clientes.'">
            </app-card-custom>

            <app-card-custom class="col-xl-4 col-md-6 col-12 pb-4" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="500" [imageSrc]="'assets/images/templates.jpg'" [demoSrc]="'portafolio/2'" [srcSaberMas]="" [btnText]="'Ver plantillas'" [title]="'Webs Templates'"
                [description]="'Visita el catálogo de plantillas disponibles para crear tu nuevo sitio.'">
            </app-card-custom>

        </div>

    </section>
</div>
<br/>

<div class="mb-0">

    <img src="assets/images/contacto.jpg" class="w-100  responsive" style="max-height: 200px;" alt="">

</div>