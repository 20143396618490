<a id="scrollBtn" (click)="scrollTop()" class="btn btn-floating btn-scrollUp waves-effect wow  fadeInUp" hidden>
    <i class="fas fa-arrow-up"></i>
</a>

<!--presentacion-->
<div class="bg-white">

    <div class="div-titulo">
        <div class="z-depth-1 div-texto-titulo" data-aos="fade-left" data-aos-duration="2000">
            <h1 class="titulo mb-0 p-4">Desarrollador Full stack, diseñador y gamer</h1>
        </div>
    </div>

    <div class="div-presentacion col-sm-8 offset-sm-2 text-center p-4" data-aos="fade-right" data-aos-duration="2000">

        <div class="mb-4">
            <img src="assets/images/seba.jpg" alt="SUR SOFT - Sebastián Ualde Ramírez" class="img-avatar rounded-circle  z-depth-1">
        </div>
        <h2 class="subTitulo">Mi pasión es el desarrollo de aplicaciones potentes, rápidas, con interfaces intuitivas y de fácil uso, llevando las mejores experiencias a los usuarios.</h2>
    </div>

    <img src="assets/images/footerTec.svg" alt="SUR SOFT - tecnologia" class="footer-image">

</div>

<!--descripcion-->
<div class="div-descripcion">

    <div class="div-descripcion-texto z-depth-1 col-sm-10 m-sm-0 my-5" data-aos="zoom-in" data-aos-duration="1000">
        <h1 class="titulo">Hola, soy Sebastián. Gracias por visitarme.</h1>

        <p class="subTitulo">
            Desde que comencé como desarrollador hace 5 años, he trabajado para diferentes clientes, tanto en el área de desarrollo web como de diseño gráfico, en estrecho contacto con ellos para lograr el mejor ajuste de las soluciones a los requerimientos que presentan.
            Mi curiosidad me lleva a estar en constante aprendizaje y siempre en la búsqueda de aumentar mis conocimientos y habilidades.
        </p>
        <p class="subTitulo"><b>"La tarta es una mentira"</b>.</p>
    </div>
</div>

<!--Habilidades y herramientas-->
<div class=" text-center bg-white w-100 p-4">

    <div class="row justify-content-center text-center w-100 m-0 p-0">

        <!--Diseño-->
        <div data-aos="fade-right" data-aos-duration="2000" data-aos-delay="100" class="redondeado hoverable col-md-3 col-sm-5 p-2 mr-sm-2 mb-sm-0 mb-sm-0 mb-3">
            <i class="fas fa-pencil-ruler fa-3x my-4 color-orange"></i>
            <h2>Diseñador</h2>
            <hr/>

            <h3 class="color-orange">Cosas que disfruto del diseño</h3>
            <p class="parrafo">
                UI, Web, Apps, Logos, Restauraciones fotográficas, Composición de banners publicitarios, realización de manuales, modelado 3D.
            </p>
            <h3 class="color-orange">Herramientas de diseño</h3>
            <div class="row justify-content-center">
                <ul class="lista">
                    <li>
                        <a href="https://www.coreldraw.com/la/free-trials/" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Corel Draw Suite</a>
                    </li>
                    <li>
                        <a href="https://getsharex.com/" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Share X</a>
                    </li>
                    <li>
                        <a href="https://annystudio.com/software/colorpicker/" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>JPicker</a>
                    </li>
                    <li>
                        <a href="https://play.google.com/store/apps/details?id=com.brakefield.painter&hl=es&gl=US" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Infinite Painter</a>
                    </li>
                    <li>
                        <a href="https://play.google.com/store/apps/details?id=com.medibang.android.paint.tablet&hl=es&gl=US" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>MediBang Paint</a>
                    </li>
                    <li>
                        <a href="https://www.autodesk.es/products/maya/overview" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Autodesk Maya</a>
                    </li>
                    <li>
                        <a href="https://www.substance3d.com/" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Substance Painter</a>
                    </li>
                </ul>
            </div>
        </div>

        <!--Frontend-->
        <div data-aos="fade-right" data-aos-duration="2000" data-aos-delay="300" class="redondeado hoverable col-md-3 col-sm-5 p-2 mr-sm-2 mb-sm-0 mb-3">
            <img src="assets/images/front.svg" alt="" class="my-3 svg-size">
            <h2>Desarrollador front-end</h2>
            <hr/>
            <h3 class="color-orange">Lenguajes que manejo</h3>
            <p class="parrafo">
                HTML, CSS, TypeScript, JavaScript, Boostrap.
            </p>
            <h3 class="color-orange">Herramientas de desarrollo</h3>
            <div class="row justify-content-center">
                <ul class="lista">
                    <li>
                        <a href="https://code.visualstudio.com/" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Visual Studio Code</a>
                    </li>
                    <li>
                        <a href="https://www.sublimetext.com/" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Sublime Text</a>
                    </li>
                    <li>
                        <a href="https://bitbucket.org/" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Bitbucket</a>
                    </li>
                    <li>
                        <a href="https://github.com/" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Github</a>
                    </li>
                    <li>
                        <a href="https://www.mozilla.org/es-ES/" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Mozilla Firefox</a>
                    </li>
                    <li>
                        <a href="https://www.google.com/intl/es-419/chrome/" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Google Chrome</a>
                    </li>
                    <li>
                        <a href="https://www.microsoft.com/es-es/edge" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Microsoft Edge</a>
                    </li>
                    <li>
                        <a href="https://www.coreldraw.com/la/free-trials/" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Corel Draw</a>
                    </li>
                </ul>
            </div>
        </div>

        <!--Backend-->
        <div data-aos="fade-right" data-aos-duration="2000" data-aos-delay="500" class="redondeado hoverable col-md-3 col-sm-5 p-2 mr-sm-2 mb-sm-0 mb-3">
            <img src="assets/images/back.svg" alt="" class="my-3 svg-size">
            <h2>Desarrollador back-end</h2>
            <hr/>
            <h3 class="color-orange">Tecnologías que utilizo</h3>
            <p class="parrafo">
                C# 9, Linq, .NET Framework, .NET Core, .NET 5, SQL Server
            </p>
            <h3 class="color-orange">Herramientas de desarrollo</h3>
            <div class="row justify-content-center">
                <ul class="lista pl-3">
                    <li>
                        <a href="https://code.visualstudio.com/" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Visual Studio 2019</a>
                    </li>
                    <li>
                        <a href="https://www.postman.com/" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Postman</a>
                    </li>
                    <li>
                        <a href="https://bitbucket.org/" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Bitbucket</a>
                    </li>
                    <li>
                        <a href="https://github.com/" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Github</a>
                    </li>
                    <li>
                        <a href="https://swagger.io/" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Swagger</a>
                    </li>
                    <li>
                        <a href="https://trello.com/es" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Trello</a>
                    </li>
                    <li>
                        <a href="https://docs.microsoft.com/en-us/sql/ssms/download-sql-server-management-studio-ssms?view=sql-server-ver15" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>SQL Server Management Studio</a>
                    </li>
                    <li>
                        <a href="https://www.microsoft.com/es-ww/microsoft-365/visio/flowchart-software" target="blank" class="link-about"><i class="fas fa-angle-double-right mr-2"></i>Microsoft Visio</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<!--tiempo-->
<div class="pt-4 px-3">
    <div class="container">
        <h1 data-aos="zoom-in" data-aos-duration="1000" class="titulo dark-grey-text">El camino</h1>

        <!--separador-->
        <div data-aos="fade-down" data-aos-duration="1000" class="separador mx-0">
            <div class="col-5 p-0 mr-2">
                <hr />
            </div>
            <div>
                <a routerLink="inicio" data-toggle="tooltip" title="Inicio">
                    <img src="favicon.ico" style="height: 30px;" />
                </a>
            </div>
            <div class="col-5 p-0 ml-2">
                <hr />
            </div>
        </div>

        <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" class="subTitulo dark-grey-text text-center">
            Desde los comienzos siempre he avanzado en las tecnologías que utilizo y en la complejidad de los proyectos, para desarrollar mejores soluciones y crecer como profesional. Aquí puedes dar un vistazo parcial a mi evolución como dev.
        </p>
    </div>

    <div class="row">
        <div class="col-lg-8 mx-auto">

            <ol class="timeline">

                <!--Proximos proyectos-->
                <li class="timeline-element">
                    <div data-aos="fade-right" data-aos-duration="3000">
                        <h3 class="font-weight-bold dark-grey-text mb-3">Próximos proyectos</h3>
                        <p class="grey-text font-small"><time>Próximamente... <i class="fas fa-angle-double-right mr-2"></i></time></p>
                        <p><img class="img-fluid z-depth-1-half rounded" src="assets/images/basketapp.jpg" alt="..."></p>
                        <p class="text-muted">
                            En el futuro esta en espera el desarrollo de una aplicación para el manejo estadístico de equipos de Básquet que ayude a entrenadores y jugadores a mejorar en sus puntos débiles, tanto como equipo, como de manera individual.
                        </p>
                        <h3 class="font-weight-bold dark-grey-text text-center mb-3">El próximo proyecto también pude ser el tuyo.</h3>
                    </div>


                </li>
                <!--Proximos ecommerce-->
                <li class="timeline-element">
                    <div data-aos="fade-right" data-aos-duration="3000">
                        <h3 class="font-weight-bold dark-grey-text mb-3">E-Commerce Project</h3>
                        <p class="grey-text font-small"><time>Set. 2021 - actualidad <i class="fas fa-angle-double-right mr-2"></i></time></p>
                        <p><img class="img-fluid z-depth-1-half rounded" src="assets/images/ecommerce.jpg" alt="..."></p>
                        <p class="text-muted">
                            Actualmente me encuentro en el desarrollo de una aplicación de tipo E-Commerce que sea de fácil utilización para los clientes, fácil administración y totalmente personalizable para cualquier tipo de negocio. Su implementación estará Basada en Angular
                            13 para su Frontend y .NET Core API para el Backend.
                        </p>
                        <h3 class="font-weight-bold dark-grey-text text-center mb-3">Próximamente habrán más novedades. </h3>
                    </div>


                </li>
                <!--JCU Web-->
                <li class="timeline-element">
                    <div data-aos="fade-right" data-aos-duration="3000">
                        <h3 class="font-weight-bold dark-grey-text mb-3">Remake JCU Web</h3>
                        <p class="grey-text font-small"><time>Nov. 2021</time></p>
                        <p><img class="img-fluid z-depth-1-half rounded" src="assets/images/jcuweb.jpg" alt="..."></p>
                        <p class="text-muted">
                            Desarrollo de aplicación tipo portafolio en donde se implementa un rediseño de la antigua web basada en la tecnología de Blogger pasando a utilizar un fronted en Angular 13 y un backend con .NET Core y C#9.
                        </p>
                        <p>
                            <a href="https://juancarlosualde.com/" target="blank" class="link-about">Ver página <i class="fas fa-angle-double-right mr-2"></i>Juan Carlos Ualde - Arte</a>
                        </p>
                    </div>


                </li>
                <!--Sursoft -->
                <li class="timeline-element">
                    <div data-aos="fade-left" data-aos-duration="3000">
                        <h3 class="font-weight-bold dark-grey-text mb-3">Página Personal SUR Soft</h3>
                        <p class="grey-text font-small"><time>ago 2021</time></p>
                        <p><img class="img-fluid z-depth-1-half rounded" src="assets/images/sursoft.jpg" alt="..."></p>
                        <p class="text-muted">Actualización de página personal, incursionando en Angular 11 para su desarrollo y aportando a mi base de conocimiento para la posterior aplicación en nuevos proyectos.</p>

                        <p>
                            <a routerLink="inicio" class="link-about">Ir a Inicio <i class="fas fa-angle-double-right mr-2"></i>SUR Soft</a>
                        </p>
                    </div>

                </li>
                <!--Madriguera-->
                <li class="timeline-element">
                    <div data-aos="fade-right" data-aos-duration="3000">
                        <h3 class="font-weight-bold dark-grey-text mb-3">CTO Lunch Box App</h3>
                        <p class="grey-text font-small"><time>oct 2020 - feb 2021 v.1 / abr 2021 - jun 2021 v.2</time></p>
                        <p><img class="img-fluid z-depth-1-half rounded" src="assets/images/madriguera.jpg" alt="..."></p>
                        <p class="text-muted">Desarrollo de aplicación web de tipo eCommerce para la empresa La Madriguera Libros. En principio la aplicación fue desarrollada para la gestión interna de la empresa (v.1) con la posibilidad de en el futuro ampliar la misma a
                            una tienda online (v.2). Tiempo después se desarrollo la expansión web de eCommerce. La misma fue implementada con .Net Core 5, razor pages, MVC, Boostrap, SQL Server para la DB e integración con Mercado Pago para las compras
                            online.
                        </p>

                        <p>
                            <a href="https://www.lamadrigueralibros.com/" target="blank" class="link-about">Ver página <i class="fas fa-angle-double-right mr-2"></i>La Madriguera Libros</a>
                        </p>
                        <p>
                            <a href="https://madrigueralibros.sursoft.org/" target="blank" class="link-about">Ver Demo <i class="fas fa-angle-double-right mr-2"></i>La Madriguera Libros - DEMO</a>
                        </p>
                    </div>

                </li>
                <!--Odonto integral-->
                <li class="timeline-element">
                    <div data-aos="fade-left" data-aos-duration="3000">
                        <h3 class="font-weight-bold dark-grey-text mb-3">CTO Odonto Integral App</h3>
                        <p class="grey-text font-small"><time>abr 2020 - Actualidad</time></p>

                        <p><img class="img-fluid z-depth-1-half rounded" src="assets/images/odonto.jpg" alt="..."></p>

                        <p class="text-muted">Desarrollo continuo de aplicación web para la gestión de consultorios odontológicos de la empresa Odonto Integral. La misma requirió el desarrollo para la parte administrativa de las clínicas de individual para cada una y general
                            para para el super admin, sumando funcionalidades para historias clínicas de pacientes y página web general. La misma fue desarrollada con .Net Core 2.1 en principio, actualizada a 3.1 y a .Net Core 5 en la actualidad, razor
                            pages, MVC, Boostrap y SQL Server para la DB.</p>

                        <p>
                            <a href="https://odontointegraluy.com/" target="blank" class="link-about">Ver Página <i class="fas fa-angle-double-right mr-2"></i>Odonto Integral</a>
                        </p>
                        <p>
                            <a href="http://dentalnet.sursoft.org/" target="blank" class="link-about">Ver Demo <i class="fas fa-angle-double-right mr-2"></i>Odonto Integral - DEMO</a>
                        </p>
                    </div>


                </li>
                <!--Lunch Box-->
                <li class="timeline-element">
                    <div data-aos="fade-right" data-aos-duration="3000">
                        <h3 class="font-weight-bold dark-grey-text mb-3">CTO Lunch Box App</h3>
                        <p class="grey-text font-small"><time>oct 2019 - mar 2020</time></p>
                        <p class="bg-white"><img class="img-fluid z-depth-1-half rounded p-2" src="assets/images/lunch.png" alt="..."></p>
                        <p class="text-muted">Desarrollo de aplicación web para la gestión de la empresa de catering Lucn Box, la cual requeria una parte web para clientes y una parte administrativa para empleados y gerente. La misma fue desarrollada con .Net Core 2.1, C#,
                            razor pages, MVC, Boostrap y SQL Server para la DB.</p>
                        <a href="http://lunchboxdemo.sursoft.org/" target="blank" class="link-about">Ver Demo <i class="fas fa-angle-double-right mr-2"></i>Lunch Box - Catering</a>
                    </div>

                </li>
                <!--Diseños 3D-->
                <li class="timeline-element">
                    <div data-aos="fade-left" data-aos-duration="3000">
                        <h3 class="font-weight-bold dark-grey-text mb-3">Diseño Esqueleto completo 3D</h3>
                        <p class="grey-text font-small"><time>2018</time></p>

                        <iframe class="img-fluid z-depth-1-half rounded" style="height: 270px; width: 100%;" src="https://sketchfab.com/playlists/embed?collection=827dd5a931ea4c27992980a86daf76da&autostart=0" frameborder="0" allow="autoplay; fullscreen; vr" allowvr="" allowfullscreen=""
                            mozallowfullscreen="true" webkitallowfullscreen="true" onmousewheel=""></iframe>

                        <p class="text-muted">Diseño de todos los huesos del cuerpo humano para la plataforma Sketchfab, realizados con la herramienta Autodesk Maya 3D y Substance Painter.</p>
                        <a href="https://sketchfab.com/sebaualde/collections" target="blank" class="link-about">Ver Colección <i class="fas fa-angle-double-right mr-2"></i>Sketchfab Diseños 3D</a>
                    </div>

                </li>
                <!--Domos argentina-->
                <li class="timeline-element">
                    <div data-aos="fade-right" data-aos-duration="3000">
                        <h3 class="font-weight-bold dark-grey-text mb-3">Single Page Application</h3>
                        <p class="grey-text font-small"><time>2017</time></p>
                        <p><img class="img-fluid z-depth-1-half rounded" src="assets/images/domos.jpg" alt="..."></p>
                        <p class="text-muted">Demo de página empresarial para Domos Argentina, utilizando la tecnología de WordPress.</p>
                        <a href="http://domos-argentina-demo.sursoft.org/" target="blank" class="link-about">Ver Demo <i class="fas fa-angle-double-right mr-2"></i>Domos Argentina Demo</a>
                    </div>

                </li>
                <!--pagina papa-->
                <li class="timeline-element">
                    <div data-aos="fade-left" data-aos-duration="3000">
                        <h3 class="font-weight-bold dark-grey-text mb-3">CTO de Página tipo blog</h3>
                        <p class="grey-text font-small"><time>2010 - Actualidad</time></p>
                        <p><img class="img-fluid z-depth-1-half rounded" src="assets/images/papa.jpg" alt="..."></p>
                        <p class="text-muted">Primer actividad Freelance de desarrollo continuo web con la tecnología de Blogger, implementando integraciones con desarrollo de diseños 3D y utilizando herramientas de Google Maps y Google Fotos.
                        </p>
                        <a href="https://juancarlosualde.blogspot.com/" target="blank" class="link-about">Ver Página <i class="fas fa-angle-double-right mr-2"></i>Juan Carlos Ualde - Arte Bolg</a>
                    </div>

                </li>

            </ol>

        </div>
    </div>

</div>